<template>
    <div id="socialLogin">

        <div class="mt-4">
            <button type="button" class="btn btn-outline-success waves-effect waves-light btn-block" @click="socialLogin('facebook')">{{$t('components.socialLogin.accediFacebook')}}</button>
        </div>
        <div class="mt-2">
            <button type="button" class="btn btn-outline-danger waves-effect waves-light btn-block" @click="socialLogin('google')">{{$t('components.socialLogin.accediGoogle')}}</button>
        </div>
        <div class="mt-2">
            <button type="button" class="btn btn-outline-dark waves-effect waves-light btn-block" @click="socialLogin('apple')">{{$t('components.socialLogin.accediApple')}}</button>
        </div>

        <!-- Modali -->
        <div id="modalError" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalErrorLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0" id="myModalLabel">{{$t('components.socialLogin.modale.errore.titolo')}}</h5>
                    </div>
                    <div id="modal-body" class="modal-body">
                        <p id="message-body"></p>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal">{{$t('generale.ok')}}</button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div>

        <!-- Modal overlay login -->
        <div id="modalOverlayLogin" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalOverlayLoginLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content bg-transparent border-0">
                    <div id="modal-body-overlay-pause" class="modal-body">
                        <div class="w-100 text-center pb-4">
                            <img src="../assets/images/logo-concorsando-white.png">
                        </div>
                        <div class="w-100 text-center">
                            <h3 class="text-white" v-if="!isLogging">{{$t('components.socialLogin.modale.effettuaLaLoginConIlSocial')}}</h3>
                            <div v-else class="loader mx-auto"></div>
                        </div>
                        <div class="w-100 text-center" v-if="!isLogging">
                            <button type="button" class="btn btn-primary waves-effect waves-light" @click="hideOverlay(true)" data-dismiss="modal">{{$t('generale.annulla')}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /.modal-dialog -->
        </div>
        <!-- /Modal overlay login -->
        <!-- /Modali -->

    </div>
</template>

<script>
    import 'bootstrap'
    import {hello} from "../hello"
    import { mapActions, mapGetters } from 'vuex'
    import { MODULES } from "../store/modules-enum";
    import { AUTH_GETTERS } from "../store/auth/getters-enum";
    import { AUTH_ACTIONS } from "../store/auth/actions-enum";
    import _ from 'lodash';
    import $ from 'jquery'
    import * as config from '../config';
    import RuntimeConfig from "@/helpers/RuntimeConfig";
    import {storageKeysEnum} from "@/helpers/enums";
    import {loginTransition} from "../flows/login/loginFlow";
    import {LoginStates} from "../flows/login/loginStates";

    let initializedAppleId = false;

    export default {
        name: "SocialLogin",
        data () {
            return {
                isLogging: false,
                nonce: undefined,
            }
        },
        computed: {
          ...mapGetters(MODULES.AUTH, [AUTH_GETTERS.IS_AUTHENTICATED])
        },
        mounted() {
            this.initAppleID();
        },
        methods: {
            ...mapActions(MODULES.AUTH, [AUTH_ACTIONS.LOGIN]),
            socialLogin (socialType) {
                $('#modalOverlayLogin').modal({backdrop: 'static', keyboard: false})
                const context = this
                this.nonce = Date.now();
                const nonce = this.nonce;
                let configurations = {}
                let loginPopupPromise;
                const handleHelloJsErrors = e => {
                    this.hideOverlay()
                    if (e.error.code !== "cancelled" && e.error_reason !== "user_denied") {
                        console.error(e);
                        $('#modalError').modal('show')
                        $('#message-body').html(this.$t('components.socialLogin.modale.errore.generale'))
                    }
                    throw e;
                }
                if (socialType.toLowerCase() === 'facebook') {
                    configurations = { scope: 'email' }
                    loginPopupPromise = new Promise((resolve, reject) => {
                        hello(socialType)
                            .login(configurations).then(
                            userDetails => {
                                resolve({
                                    tokenSocial: userDetails.authResponse.access_token,
                                    social: socialType
                                })
                            },
                            e => reject(e))
                    }).catch(e => handleHelloJsErrors(e));
                }
                else if (socialType.toLowerCase() === 'google') {
                    configurations = {
                        scope: 'https://www.googleapis.com/auth/userinfo.email, https://www.googleapis.com/auth/userinfo.profile',
                        response_type: 'token id_token'
                    }
                    loginPopupPromise = new Promise((resolve, reject) => {
                        hello(socialType)
                            .login(configurations).then(
                            userDetails => {
                                resolve({
                                    tokenSocial: userDetails.authResponse.id_token,
                                    social: socialType
                                })
                            },
                            e => reject(e))
                    }).catch(e => handleHelloJsErrors(e));
                }
                else if (socialType.toLowerCase() === 'apple') {
                    loginPopupPromise = this.appleLogin()
                        .then(res => ({
                            tokenSocial: res.authorization.code,
                            social: socialType
                        }))
                        .catch(e => {
                            let isNewLogin = _.get(e, "error") === "user_trigger_new_signin_flow";
                            if (isNewLogin) {
                                // Se l'utente ha triggerato un nuovo login non devo fare niente (ma la promessa deve andare comunque in errore)
                                throw e;
                            }
                            this.hideOverlay();
                            let isPopupClosedError = _.get(e, "error") === "popup_closed_by_user"
                            let isUserCancelError = _.get(e, "error") === "user_cancelled_authorize"
                            if (!isPopupClosedError && !isUserCancelError) {
                                console.error(e);
                                $('#modalError').modal('show')
                                $('#message-body').html(this.$t('components.socialLogin.modale.errore.generale'))
                            }
                            throw e;
                        })
                }
                loginPopupPromise
                    .then(loginConfig => {
                        if (nonce < this.nonce) {
                            // Se il nonce è stato aggiornato significa che questa richiesta non è più quella corrente, quindi
                            // va ignorata
                            return;
                        }
                        context.isLogging = true
                        $('#modalOverlayLogin').modal({backdrop: 'static', keyboard: false})
                        if (context.getIsAuthenticated() !== true) {
                            context.login(loginConfig)
                                .then((response) => {
                                    try {
                                        // Se il login è andato a buon fine eliminiamo l'eventuale email salvata in remember me
                                        localStorage.removeItem(storageKeysEnum.EMAIL);
                                    } catch (e) {}
                                    $('#modalOverlayLogin').modal('hide')
                                    loginTransition(LoginStates.LOGIN_SOCIAL, response)
                                })
                                .catch((error) => {
                                    context.hideOverlay()
                                    $('#modalError').modal('show')
                                    if (error !== undefined) {
                                        if (error.status === 429) {
                                            $('#message-body').html(context.$t('components.socialLogin.modale.errore.troppeRichieste') + ' <a href=\'http://m.me/AssistenzaTecnicaConcorsando\'>Assistenza</a>')
                                            return
                                        }
                                    }
                                    $('#message-body').html(context.$t('components.socialLogin.modale.errore.generale'))
                                })
                        }
                    })
                    // Gli errori che fanno bubbling fin qui sono quelli dei login social, e sono già stati gestiti localmente
                    .catch(() => {})
            },
            appleLogin() {
                // eslint-disable-next-line no-undef
                return AppleID.auth.signIn()
            },
            initAppleID() {
                if (!initializedAppleId && window.AppleID) {
                    // eslint-disable-next-line no-undef
                    AppleID.auth.init({
                        ...config.appleIdStaticConfig,
                        redirectURI: `${RuntimeConfig.instance.appleRedirectUrl}`,
                        nonce: `nonce-${Date.now()}`,
                    });
                    initializedAppleId = true;
                }
            },
            getIsAuthenticated () {
                return this.isAuthenticated
            },
            hideLoader () {
                $('.loading').hide()
            },
            hideOverlay (updateNonce = false) {
                if (updateNonce) {
                    this.nonce = Date.now()
                }
                $('#modalOverlayLogin').modal('hide')
                this.isLogging = false
            }
        }
    }
</script>

<style scoped lang="scss">
    html.theme-light, html.theme-dark {
        #modalOverlayLogin {
            background-color: #00000082;
        }
    }
</style>
