<template>
    <div id="auth">
        <loader class="loading" style="display: none"></loader>

        <Login v-if="$route.query.type === 'login'"></Login>
        <SignUp v-else></SignUp>
    </div>
</template>

<script>
    import Login from "../components/Login"
    import SignUp from "../components/SignUp"
    import Loader from "@/components/Loader";

    export default {
        name: "Auth",
        components: {
          Login,
          SignUp,
          Loader,
        }
    }
</script>

<style scoped>

</style>