/**
 * Questi sono gli stati di una FSM, in pratica lo stato della FSM non viene gestito in memoria, ma ogni volta
 * che chiediamo una transizione passiamo lo stato corrente (e gli eventuali parametri)
 */
export const LoginStates = {
    REGISTER: 'REGISTER', // registrazione
    LOGIN_SOCIAL: 'LOGIN_SOCIAL', // social login
    LOGIN: 'LOGIN', // Login
    VERIFY_EMAIL: 'VERIFY_EMAIL', // pagina /verifica-email
    CHECK_EMAIL: 'CHECK_EMAIL', // pagina /check-email
    LOGGED: 'LOGGED',
}
