import {LoginStates} from "./loginStates";
import AppSingleton from "../../helpers/AppSingleton";
import {routesEnums} from "../../helpers/enums";
import store from "../../store";

export function loginTransition(state, payload) {
    const app = AppSingleton.instance;
    switch(state) {
        case LoginStates.LOGIN_SOCIAL:
            const response = payload;
            if (response === null) {
                app.$router.push({name: routesEnums.VERIFY_EMAIL})
            } else if (response.token_api === null) {
                app.$router.push({name: routesEnums.VERIFY_EMAIL, params: { flagPrivacy: response.flag_privacy, emailComunicazioni: response.email_comunicazioni } })
            } else {
                if (store.getters['auth/isEmailVerified']) {
                    app.$router.push({name: routesEnums.HOME});
                } else {
                    app.$router.push({name: routesEnums.SEND_EMAIL_VERIFICATION});
                }
            }
            return;
        case LoginStates.LOGIN:
        case LoginStates.REGISTER:
        case LoginStates.VERIFY_EMAIL:
            if (store.getters['auth/isEmailVerified']) {
                app.$router.push({name: routesEnums.HOME});
            } else {
                app.$router.push({name: routesEnums.SEND_EMAIL_VERIFICATION});
            }
            return;
        case LoginStates.CHECK_EMAIL:
            app.$router.push({name: routesEnums.HOME});
            return;
    }
}
