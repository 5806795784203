import Vue from 'vue'
import hello from 'hellojs'

hello.init(
    {
        facebook: '345392772187229',
        google: '582664110079-4lo490j28mjma5cmr9ftbr78br2omsi0.apps.googleusercontent.com'
    },
    {
        redirect_uri: process.env.VUE_APP_HELLOJS_REDIRECT_URL
    }
);

export default ({ app }) => {
    // Set hello instance on app
    Vue.prototype.$hello = hello
}

export { hello }
